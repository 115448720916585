
import CurrencyInput from "@/components/widgets/CurrencyInput.vue";
import comarcas from "@/core/data/comarcas";
import { numeroMenoresOpcionesTodas } from "@/core/data/numeroMenores";
import { tipologiasFamiliaTodas } from "@/core/data/tipologiasFamilia";
import tratamientos from "@/core/data/tratamientos";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Page from "@/layout/Page.vue";
import { AuthModule, FamiliasListadoModule } from "@/store/modules";
import { IAMFile } from "@/types";
import imageCompression from "browser-image-compression";
import Dropzone from "dropzone";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface Familia {
  tratamiento: string;
  nombre: string;
  apellidos: string;
  dni: string;
  email: string;
  telefono: string;
  direccion: string;
  provincia: string;
  localidad: string;
  comarcaId: string;
  tipologiaFamilia: string;
  numeroMenores: string;
  conIngresos?: boolean;
  ingresos: string;
  familiaPrioritaria?: boolean;
  documentosNuevos?: IAMFile[];
}

export default defineComponent({
  name: "formularios-familia-detalle",
  components: {
    ErrorMessage,
    Page,
    CurrencyInput,
  },

  methods: {
    includeDocumentoNuevo(file) {
      const reader = new FileReader();
      return new Promise<void>((resolve, reject) => {
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.documentosNuevos.push({
            nombre: file.name,
            data: reader.result?.toString() || "",
          });
          resolve();
        };
        reader.onerror = reject;
      });
    },
    deleteDocumentoNuevo(file) {
      this.documentosNuevos = this.documentosNuevos.filter(function (item) {
        return item.nombre !== file.name;
      });
    },
    compressImage(file) {
      const compressionOptions = { maxSizeMB: 0.3 };
      return imageCompression(file, compressionOptions);
    },
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const familia = ref();
    const store = useStore();
    const module = getModule(FamiliasListadoModule, store);
    const authModule = getModule(AuthModule, store);

    const error = computed(() => module.errors);

    const userComarcaId = authModule.user.comarcaId;

    watch(error, () => {
      if (!error.value) return;

      Swal.fire({
        text:
          t("errors." + (error.value.error.code ?? error.value.error.name)) ??
          error.value.error.name,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("dialogs.okButton"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    });

    const cargaDetalle = ref<boolean>(false);
    const guardaDetalle = ref<boolean>(false);

    onBeforeMount(async () => {
      setCurrentPageBreadcrumbs(t("menu.familia"), [
        t("menu.planCorresponsables"),
        { title: t("menu.familias"), path: "/listados/familias" },
      ]);
    });

    const schema = Yup.object({
      tratamiento: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.tratamiento.label")),
      nombre: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.nombre.label")),
      apellidos: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.apellidos.label")),
      dni: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.dni.label")),
      email: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.email.label")),
      telefono: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.telefono.label")),
      direccion: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.direccion.label")),
      provincia: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.provincia.label")),
      localidad: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.localidad.label")),
      comarcaId: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.comarca.label")),
      tipologiaFamilia: Yup.string().required(),
      numeroMenores: Yup.string().required(),
      conIngresos: Yup.boolean(),
      ingresos: Yup.string().ensure(),
      familiaPrioritaria: Yup.boolean(),
      documentosNuevos: Yup.array(),
    });

    const { handleSubmit } = useForm<Familia>({
      validationSchema: schema,
    });

    const { value: tratamiento } = useField("tratamiento");
    const { value: nombre } = useField("nombre");
    const { value: apellidos } = useField("apellidos");
    const { value: dni } = useField("dni");
    const { value: email } = useField("email");
    const { value: telefono } = useField("telefono");
    const { value: direccion } = useField("direccion");
    const { value: provincia } = useField("provincia");
    const { value: localidad } = useField("localidad");
    const { value: comarcaId } = useField("comarcaId");
    const { value: conIngresos } = useField("conIngresos");
    const { value: tipologiaFamilia } = useField("tipologiaFamilia");
    const { value: numeroMenores } = useField("numeroMenores");
    const { value: ingresos } = useField("ingresos");
    const { value: familiaPrioritaria } = useField("familiaPrioritaria");

    if (userComarcaId) {
      comarcaId.value = userComarcaId;
    }
    const { value: documentosNuevos } = useField<IAMFile[]>("documentosNuevos");

    return {
      tratamientos,
      schema,
      cargaDetalle,
      guardaDetalle,
      familia,
      tratamiento,
      nombre,
      apellidos,
      dni,
      email,
      telefono,
      direccion,
      provincia,
      localidad,
      comarcaId,
      userComarcaId,
      isAdmin: !userComarcaId,
      comarcas,
      familiaPrioritaria,
      conIngresos,
      tipologiaFamilia,
      numeroMenores,
      ingresos,
      documentosNuevos,
      numeroMenoresOpcionesTodas,
      tipologiasFamiliaTodas,
      onSubmit: handleSubmit(async (values) => {
        guardaDetalle.value = true;
        values.ingresos = values.ingresos?.toString() ?? "";
        try {
          await module
            .create({
              comarcaId: userComarcaId,
              data: values,
            })
            .then(() => {
              if (!module.errors)
                Swal.fire({
                  text: t("listados.familiaDetalle.guardar.mensaje.exito"),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("dialogs.okButton"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.replace("/listados/familias");
                });
            });
        } catch (err) {
          Swal.fire({
            text: t("formularios.familiaDetalle.guarda.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          guardaDetalle.value = false;
        }
      }),
    };
  },

  mounted() {
    const dropzoneNuevosDocumentos = new Dropzone(
      "#kt_dropzonejs_nuevos_documentosFamilia_nueva",
      {
        url: process.env.VUE_APP_API_URL,
        // The name that will be used to transfer the file
        paramName: "documento",
        maxFilesize: 10, // MB
        addRemoveLinks: true,
        uploadMultiple: true,
        autoProcessQueue: false,
        acceptedFiles: "image/*,application/pdf",
      }
    );
    dropzoneNuevosDocumentos.on("addedfile", async (file) => {
      const fileToAdd = file.type.includes("image/")
        ? await this.compressImage(file)
        : file;

      this.includeDocumentoNuevo(fileToAdd);
    });
    dropzoneNuevosDocumentos.on("removedfile", this.deleteDocumentoNuevo);
  },
});
